import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {getSearchProducts} from "../../../../redux/actionCreators/getCreators/getGlobal";
import HeaderSearchDropdown from "./HeaderSearchDropdown";
import HeaderInput from "./HeaderInput";
import AwesomeDebouncePromise from "awesome-debounce-promise";

const HeaderSearch = props => {

    const [search, setSearch] = useState('')
    const [drop, setDrop] = useState(false)

    const searchApi = () => {
        if (search.length > 3) {
            window.axios.get(`/products?&search=${encodeURI(search)}`, {
                params: {
                    pageSize: 10
                }
            })
                .then(res => {
                    props.getSearchProducts(res.data.data)
                })
        }
    }

    const debounce = AwesomeDebouncePromise(searchApi, 500)


    useEffect(() => {
        debounce()
    }, [search])

    return (
        <div className="header-search">
            <div className="header-search__form" onMouseLeave={() => {
                setDrop(false)
            }}>
                <HeaderInput setDrop={setDrop} setSearch={setSearch}/>
                {
                    props.products.length > 0 && drop ? (
                        <HeaderSearchDropdown search={search} products={props.products}/>
                    ) : ''
                }
            </div>
        </div>
    )
}

const mapStateToProps = store => {
    return {
        products: store.searchReducer.products,
    }
}

const mapDispatchToProps = {
    getSearchProducts,
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSearch)