import React from "react";
import {connect} from 'react-redux'
import filter from '../../../../img/catalog/filter-mobile.svg'
import sort from '../../../../img/catalog/sort-mobile.svg'
import equipment from '../../../../img/catalog/equipment-selection.svg'
import {
    toggleSortModal,
    toggleModal,
    toggleQuiz,
} from "../../../../redux/actionCreators/modalsCreator";

const CatalogMobileHeader = (props) => {

    return (
        <>
            <div className="catalog-mobile-header">
                <button className="catalog-mobile-header__filter-btn" onClick={props.openFilterDrop}>
                    <img src={filter} alt="Фільтр" />
                    <span className="catalog-mobile-header__filter-text">Фільтр</span>
                </button>
                <div className="catalog-mobile-header-head">
                    <button className="catalog-mobile-header__sort-btn">
                        <img src={sort} alt="Сортування" />
                        <span
                            className="catalog-mobile-header__sort-text"
                            onClick={() => {
                                props.toggleSortModal(true)
                                props.toggleModal(true)
                            }}
                        >
                    Сортувати</span>
                    </button>
                    {
                        props.categoryId === 4 ? (
                            <div className="catalog-mobile-header__equipment-select-link" onClick={() => {
                                props.toggleModal(true)
                                props.toggleQuiz(true)
                            }}>
                                <img src={equipment} alt="Підбір обладнання" />
                                <span className="catalog-mobile-header__selection-text">Підібрати обладнання</span>
                            </div>
                        ) : ''
                    }
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = {
    toggleSortModal,
    toggleModal,
    toggleQuiz
}

export default connect(null, mapDispatchToProps)(CatalogMobileHeader)