import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import MainBanner from "./MainBaner/MainBanner";
import MainPartnerBlock from "./MainPartnerBlock/MainPartnerBlock";
import MainServices from "./MainServices/MainServices";
import ProductsSlider from "../globalComponents/ProductsSlider/ProductsSlider";
import SubscribeForm from "../globalComponents/SubscribeForm/SubscribeFrom";
import {
    getNewProducts,
    getDiscountProducts,
    getMainPageBanners,
    getMainPagePartners,
} from "../../redux/actionCreators/getCreators/getMainPage";
import {
    toggleModal,
    toggleResetPasswordModal,
} from "../../redux/actionCreators/modalsCreator";
import './Main.scss'
import CategoriesList from "../globalComponents/CategoriesList/CategoriesList";
import ScrollToTopOnMount from "../../ScrolToTop";
import MetaTags from "react-meta-tags";
import JsonLd from '../globalComponents/JsonLd/JsonLd';
import {_domain, _images} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";

const Main = props => {

    const jsonLdBreadcrumbList = {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "item": {
                    "@id": _domain+'/',
                    "name": "Головна"
                }
            },
            {
                "@type": "ListItem",
                "position": 2,
                "item": {
                    "@id": _domain+'#header',
                    "name": "Система кондиціонування оптом та в роздріб👈"
                }
            }
        ]
    };

    let jsonLdOrganization = {
        "@context": "http://schema.org",
        "@type": "Organization",
        "url": _domain,
        "priceRange": "$-$$$$$$",
        "name": "Ліка Комфорт - системи вентиляції, кондиціонування та енергозбереження від провідних світових виробників",
        "logo": _domain+logo,
        "image": props.banners.map(val => {return _images+val.image}),
        "sameAs": [
            "https://www.instagram.com/lika_comfort/?igshid=1qxag6rjd3m53",
            "https://www.facebook.com/lika.net.ua/",
            "https://www.youtube.com/channel/UChJCfbehCQ2g6UNWmwZtykA",
        ],
        "currenciesAccepted": "UAH",
        "paymentAccepted": "cash, credit card",
        "hasMap": "https://www.google.com/maps/dir//%D1%83%D0%BB%D0%B8%D1%86%D0%B0+%D0%94%D1%83%D1%85%D0%BD%D0%BE%D0%B2%D0%B8%D1%87%D0%B0,+73,+%D0%9C%D1%83%D0%BA%D0%B0%D1%87%D0%B5%D0%B2%D0%BE,+%D0%97%D0%B0%D0%BA%D0%B0%D1%80%D0%BF%D0%B0%D1%82%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C/@48.4447515,22.7269453,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4739ac79cf5a5415:0x66ef50461e745ede!2m2!1d22.729134!2d48.444748!3e0?hl=ru-RU",
        "address": {
            "@type": "PostalAddress",
            "@id": _domain,
            "name": "Ліка Комфорт Мукачево",
            "addressRegion": "Закарпатська область",
            "addressLocality": "Мукачево",
            "postalCode": "89600",
            "streetAddress": "вул. Автомобілістів, 22 б",
            "telephone": "+38 050 428 04 17",
            "email": "sell@lika.net.ua",
            "addressCountry": "UA"
        },
        "contactPoint": [
            {
                "@type": "ContactPoint",
                "telephone": "+38 050 428 04 17",
                "contactType": "Телефон №1"
            },
            {
                "@type": "ContactPoint",
                "telephone": "+38 067 112 69 26",
                "contactType": "Телефон №2"
            },
        ],
        "openingHoursSpecification": [
            {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday"
                ],
                "opens": "09:00",
                "closes": "18:00"
            },
            {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                    "Saturday",
                ],
                "opens": "09:00",
                "closes": "15:00"
            },
        ]
    };

    const [seoText, setSeoText] = useState()

    useEffect(() => {
        window.axios.get(`/banners?type=main_page`)
            .then(res => {
                props.getMainPageBanners(res.data.data)
            })
        window.axios.get(`/brands`)
            .then(res => {
                props.getMainPagePartners(res.data.data)
            })
        window.axios.get(`/seo_text/main`)
            .then(res => {
                setSeoText(res.data.text)
            })
        if (props.history.location.search.split('').splice(0, 7).join('') === '?token=') {
            props.toggleModal(true)
            props.toggleResetPasswordModal(true)
        }
    }, [])

    return (
        <div className="content-wrap">
            <JsonLd data={jsonLdBreadcrumbList} />
            <JsonLd data={jsonLdOrganization} />
            <MetaTags>
                <title>Кліматична техніка в Мукачево, купити кліматичну техніку для дому, кращі ціни в Ужгороді та Львові</title>
                <meta name="description" content="Купити кліматична техніка для дому в Ужгороді, Львові. ☀ ☁ Низькі ціни на кліматичну техніку для дому. Доставка по Україні ☝ Тільки перевірені виробники ✴ ☂ Ціни, відгуки, характеристики, огляди." />
                <meta property="og:locale" content="ua_UA" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={document.title} />
                <meta property="og:description" content="Купити кліматична техніка для дому в Ужгороді, Львові. ☀ ☁ Низькі ціни на кліматичну техніку для дому. Доставка по Україні ☝ Тільки перевірені виробники ✴ ☂ Ціни, відгуки, характеристики, огляди." />
                <meta property="og:url" content={_domain+window.location.pathname} />
                <meta property="og:image" content={_domain+logo} />
                <meta property="og:site_name" content="Ліка Комфорт" />
            </MetaTags>
            <ScrollToTopOnMount />
            <div className="content">
                <main className="main-page">
                    <h1 style={{visibility: 'hidden', height: '0px'}} >Кліматична техніка</h1>

                    {
                        props.banners.length > 0 ? (
                            <MainBanner banners={props.banners}/>
                        ) : null
                    }
                    <div className="container">
                        <CategoriesList />
                    </div>

                    {
                        props.productsNew.length === 0? '' : <ProductsSlider products={props.productsNew} name={'Нові товари'}/>
                    }
                    {
                        props.productsDiscount.length === 0? '' : <ProductsSlider products={props.productsDiscount} name={'Акційні пропозиції'}/>
                    }
                    <div className="container">
                        <MainServices />
                    </div>
                    <div className="container seo-text" dangerouslySetInnerHTML={{__html:[seoText]}} />
                    <MainPartnerBlock />
                    <SubscribeForm/>
                </main>
            </div>
        </div>
    )
}

const mapStateToProps = (store) => {
    return{
        productsNew: store.newProductsSliderReducer.newProducts,
        productsDiscount: store.discountProductsSliderReducer.discountSlider,
        feedbacks: store.productPageReducer.feedbacks,
        banners: store.bannersReducer.banners,
    }
}

const mapDispatchToProps = {
    getNewProducts,
    getDiscountProducts,
    getMainPageBanners,
    getMainPagePartners,
    toggleModal,
    toggleResetPasswordModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)