import React, {useState} from 'react'
import searchIco from "../../../img/header/search-ico-mobile.svg";
import {connect} from 'react-redux'
import {searchProducts, setSearchUrlFilters} from "../../../redux/actionCreators/searchReducer";
import {getSearchProducts} from "../../../redux/actionCreators/getCreators/getGlobal";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";

const HeaderBottomSearch = props => {

    const history = useHistory()

    const [search, setSearch] = useState()

    return (
        <div className="search-mobile-wrap">
            <div className="search-mobile">
                <input
                    className="search-mobile__bar"
                    type="search"
                    name="search-bar"
                    placeholder="Пошук"
                    onChange={e => {
                        setSearch(e.target.value)
                    }}
                    onKeyPress={e => {
                        if(e.key === 'Enter') {
                            props.searchProducts(search)
                            props.setSearchUrlFilters(`/search?q=${search}`)
                            history.push(`/search?q=${search}`)
                        }
                    }}
                />
                <Link to={`/search?q=${search}`} className="search-mobile__btn" onClick={() => {
                    props.searchProducts(search)
                    props.setSearchUrlFilters(`/search?q=${search}`)
                }}>
                    <img className="search-mobile__ico" src={searchIco} alt='пошук'/>
                </Link>
            </div>
        </div>
    )
}

const mstp = store => {
    return {
        searchUrlFilters: store.searchReducer.searchUrlFilters,
    }
}

const mapDispatchToProps = {
    getSearchProducts,
    setSearchUrlFilters,
    searchProducts,
}

export default connect(mstp, mapDispatchToProps)(HeaderBottomSearch)