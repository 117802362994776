import React from 'react'

const CompareModalFalse = () => {
    return (
        <>
            <h3 className="modal-compare__title">Порівняння товарів</h3>
            <div className="modal-compare__body modal-compare__body--empty">
                <p className="modal-compare__body-title">
                    Не додано жодного товару для порівняння!
                </p>
                <p className="modal-compare__body-subtitle">
                    Додайте товари для порівняння за характеристиками зі сторінки каталогу чи сторінки товару
                </p>
            </div>
        </>
    );
};

export default CompareModalFalse
