import React from 'react'
import CompareModalFalse from "./CompareModalFalse";
import CompareModalTrue from "./CompareModalTrue";

const CompareModal = (props) => {


    return (
        <div className="header-actions__modal-wrap" onMouseLeave={() => {
            props.setCompareModal(false)
        }}>
            <section className={`modal modal-compare ${props.compareModal? 'active': ''}`}>
                <div className="modal__close" onClick={props.openCompare}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19 6.974L17.026 5L12 10.026L6.974 5L5 6.974L10.026 12L5 17.026L6.974 19L12 13.974L17.026 19L19 17.026L13.974 12L19 6.974Z"
                            fill="#868686"/>
                    </svg>
                </div>
                {
                    props.products.length === 0? <CompareModalFalse /> : <CompareModalTrue setCompareModal={props.setCompareModal} products={props.products}/>
                }
            </section>
        </div>
    )
}

export default CompareModal