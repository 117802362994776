import React from 'react'
import CompareModalItem from "./CompareModalItem";
import {Link} from "react-router-dom";


const CompareModalTrue = (props) => {

    return (
        <>
            <h3 className="modal-compare__title">Порівняння товарів</h3>
            <div className="modal-compare__body modal-compare__body--filled">
                <h4 className="modal-compare__body-title">Кондиціонери</h4>
                <div className="modal-compare__items-wrap">
                    <CompareModalItem products={props.products}/>
                </div>
                <Link className="modal-compare__compare-btn btn-light" to="/compare" onClick={() => {
                    props.setCompareModal(false)
                }}>Порівняти</Link>
            </div>
        </>
    )
}

export default CompareModalTrue