import React, {useEffect, useState} from 'react'
import SubscribeForm from "../globalComponents/SubscribeForm/SubscribeFrom";
import Slider from 'react-slick'
import {connect} from 'react-redux'
import {getMainPagePartners} from "../../redux/actionCreators/getCreators/getMainPage";
import {getEvents} from "../../redux/actionCreators/getCreators/getAboutUs";
import './AboutUs.scss'
import goal1 from '../../img/about-us/goal1.svg'
import goal2 from '../../img/about-us/goal2.svg'
import goal3 from '../../img/about-us/goal3.svg'
import goal4 from '../../img/about-us/goal4.svg'
import value from '../../img/about-us/values-img.jpg'
import dot from '../../img/li-dot.svg'
import reason1 from '../../img/about-us/reason1.svg'
import reason2 from '../../img/about-us/reason2.svg'
import reason3 from '../../img/about-us/reason3.svg'
import reason4 from '../../img/about-us/reason4.svg'
import reason5 from '../../img/about-us/reason5.svg'
import AboutUsSliderPrev from "./AboutUsSliderPrev";
import AboutUsSliderNext from "./AboutUsSliderNext";
import {Link} from "react-router-dom";
import AboutUsEventSliderPrev from "./AboutUsEventSliderPrev";
import AboutUsEventSliderNext from "./AboutUsEventSliderNext";
import ScrollToTopOnMount from "../../ScrolToTop";
import {_images} from "../../API";
import MetaTags from "react-meta-tags";
import {_domain} from "../../API";
import logo from "../../img/logoNew/logoLikaComfortLigth.svg";
import MainPartnerBlock from "./../Main/MainPartnerBlock/MainPartnerBlock";

const AboutUs = props => {
    useEffect(() => {
        window.axios.get(`/brands`)
            .then(res => {
                props.getMainPagePartners(res.data.data)
            })
        window.axios(`${encodeURI(`/posts?&tag=Навчання`)}`)
            .then(res => {
                props.getEvents(res.data.data)
            })
    }, []);

    const [count, setCount] = useState(1)

    const settings = {
        infinite: false,
        slidesToShow: 10,
        slidesToScroll: 3,
        adaptiveHeight: true,
        autoplay: false,
        speed: 500,
        autoplaySpeed: 3000,
        arrows: true,
        dots: false,
        waitForAnimate: true,
        prevArrow: <AboutUsSliderPrev/>,
        nextArrow: <AboutUsSliderNext/>,
        responsive: [
            {
                breakpoint: 1384,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 3,
                    arrows: false,
                }
            },
            {
                breakpoint: 940,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 3,
                    arrows: false,
                }
            },
            {
                breakpoint: 620,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 2,
                    arrows: false,
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                    arrows: false,
                }
            },
        ]
    }

    const events = {
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        adaptiveHeight: true,
        autoplay: false,
        speed: 500,
        autoplaySpeed: 3000,
        waitForAnimate: true,
        arrows: true,
        dots: false,
        // cssEase: '',
        prevArrow: <AboutUsEventSliderPrev/>,
        nextArrow: <AboutUsEventSliderNext/>,
        beforeChange: (current, next) => setCount(next + 1),
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1140,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 870,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 612,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    }

    return (
        <div className="content-wrap">
            <ScrollToTopOnMount />
            <MetaTags>
                <title>Про компанію | Ліка-Комфорт лідера постачання та монтажу кліматичної техніки, вентиляції та енергозберігаючих систем</title>
                <meta name="description" content={`Компанія Ліка-Комфорт представляє на українському ринку ☀ ☁ системи вентиляції, кондиціонування та енергозбереження від провідних світових виробників.`} />
                <meta property="og:locale" content="ua_UA" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Про компанію | Ліка-Комфорт лідера постачання та монтажу кліматичної техніки, вентиляції та енергозберігаючих систем" />
                <meta property="og:description" content="Компанія Ліка-Комфорт представляє на українському ринку ☀ ☁ системи вентиляції, кондиціонування та енергозбереження від провідних світових виробників." />
                <meta property="og:url" content={_domain+window.location.pathname} />
                <meta property="og:image" content={_domain+logo} />
                <meta property="og:site_name" content="Ліка Комфорт" />
            </MetaTags>
            <div className="content">
                <main className="about-us">
                    <div className="container">
                        <div className="about-us__block">
                            <h2 className="page-title">Про нас</h2>
                            <div className="about-us__section about-us__section--top">
                                <div className="about-us__container">
                                    <p className="about-us__paragraph">Компанія <span>Ліка-Комфорт </span>з 2004 р.
                                        представляє на українському ринку системи вентиляції, кондиціонування та
                                        енергозбереження від провідних світових виробників.</p>
                                    <p className="about-us__paragraph">Багаторічний досвід роботи на понад 500
                                        реалізованих проектів, використання ефективних і сучасних інженерних рішень,
                                        висококваліфікований персонал, чітка організація діяльності та ефективна
                                        взаємодія всіх підрозділів компанії забезпечує стабільні позиції компанії на
                                        ринку і залучає партнерів в середовищі проектних, монтажних і будівельних
                                        організацій, комерційних структур, приватних і корпоративних клієнтів.</p>
                                </div>
                                <div className="about-us__achievement">
                                    <div className="about-us__achievement-item">
                                        <div className="about-us__achievement-number">17</div>
                                        <div className="about-us__achievement-text">років на ринку</div>
                                    </div>
                                    <div className="about-us__achievement-item">
                                        <div className="about-us__achievement-text-small">понад</div>
                                        <div className="about-us__achievement-number">500</div>
                                        <div className="about-us__achievement-text">реалізованих проектів</div>
                                    </div>
                                    <div className="about-us__achievement-item">
                                        <div className="about-us__achievement-text-small">понад</div>
                                        <div className="about-us__achievement-number">22</div>
                                        <div className="about-us__achievement-text">брендів</div>
                                    </div>
                                    <div className="about-us__achievement-item">
                                        <div className="about-us__achievement-text-bold">Адекватна</div>
                                        <div className="about-us__achievement-text">цінова політика</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="about-us__block">
                            <h2 className="page-title">Наша мета</h2>
                            <section className="about-us__tabs about-us__tabs--goals">
                                <div className="about-us__tab">
                                    <img src={goal1} alt=""/>
                                    <p className="about-us__tab-text">Реалізовувати високотехнологічні рішення та
                                        проекти разом з розумною економією коштів наших Клієнтів.</p>
                                </div>
                                <div className="about-us__tab">
                                    <img src={goal2} alt=""/>
                                    <p className="about-us__tab-text">Надати доступ українським споживачам та компаніям
                                        до отримання якісних товарів і послуг у сфері клімату.</p>
                                </div>
                                <div className="about-us__tab">
                                    <img src={goal3} alt=""/>
                                    <p className="about-us__tab-text">Забезпечити вітчизняним компаніям найбільш вигідні
                                        умови ведення бізнесу.</p>
                                </div>
                                <div className="about-us__tab">
                                    <img src={goal4} alt=""/>
                                    <p className="about-us__tab-text">Відкрити ринок України для світових виробників
                                        по-справжньому якісного новітнього обладнання вентиляції та кондиціонування.</p>
                                </div>
                            </section>
                        </div>
                        <div className="about-us__block partners-block">
                            <h2 className="page-title">Бренди</h2>
                            <p className="about-us__paragraph">Сьогодні в каталог нашої компанії входить 22 бренди, що
                                пропонують останні досягнення Європи, Азії та США в сфері управління кліматом. </p>
                            <p className="about-us__paragraph">Серед продукції, яку ми поставляємо своїм клієнтам, -
                                промислові та напівпромислові агрегати, а також установки, придатні для приватного
                                користування. Асортимент продукції та ціновий діапазон настільки широкі, що можуть
                                задовольнити запит клієнтів з різними потребами.</p>
                            <div className="slider-container">
                                <div className="brands-slider-wrap">
                                    <MainPartnerBlock />
                                </div>
                            </div>
                        </div>
                        <div className="about-us__block">
                            <div className="about-us__values">
                                <div className="about-us__values-img">
                                    <img src={value} alt=""/>
                                </div>
                                <div className="about-us__values-text">
                                    <h2 className="page-title">Цінності компанії</h2>
                                    <p className="about-us__values-p">Ми цінуємо міцні та взаємовигідні відносини з
                                        Клієнтами, в основі яких лежать наші ключові принципи:</p>
                                    <ul className="about-us__list">
                                        <li className="about-us__list-item">
                                            <img className="li-dot" src={dot} alt=""/>Якість
                                        </li>
                                        <li className="about-us__list-item">
                                            <img className="li-dot" src={dot} alt=""/>Надійність
                                        </li>
                                        <li className="about-us__list-item">
                                            <img className="li-dot" src={dot} alt=""/>Партнерство
                                        </li>
                                        <li className="about-us__list-item">
                                            <img className="li-dot" src={dot} alt=""/>
                                            Довгосторкова співпраця
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="about-us__block">
                            <h2 className="page-title">5 причин працювати з Ліка-Комфорт</h2>
                            <section className="about-us__tabs about-us__tabs--reasons">
                                <div className="about-us__tab">
                                    <img src={reason1} alt=""/>
                                    <h5 className="about-us__tab-title">Команда професіоналів</h5>
                                    <p className="about-us__tab-text">Наші спеціалісти гарантують Клієнтам різносторонню
                                        технічну підтримку, від моменту вибору обладнання до остаточного запуску
                                        систем.</p>
                                </div>
                                <div className="about-us__tab">
                                    <img src={reason2} alt=""/>
                                    <h5 className="about-us__tab-title">Індивідуальний підхід</h5>
                                    <p className="about-us__tab-text">Маючи багаторічний досвід, ми не підходимо до
                                        проектів однотипно, а завжди враховуємо індивідуальну ситуацію та побажання
                                        Замовника. Кожен об’єкт ми оцінюємо, як нову задачу яка вимагає максимально
                                        ефективного вирішення.</p>
                                </div>
                                <div className="about-us__tab">
                                    <img src={reason3} alt=""/>
                                    <h5 className="about-us__tab-title">Комплексний підхід</h5>
                                    <p className="about-us__tab-text">Процес реалізації кожного проекту перебуває під
                                        суворим контролем спеціалістів нашої команди від моменту першого звернення до
                                        інструктажу Клієнта з установленим обладнанням.</p>
                                </div>
                                <div className="about-us__tab">
                                    <img src={reason4} alt=""/>
                                    <h5 className="about-us__tab-title">Адекватна цінова політика</h5>
                                    <p className="about-us__tab-text">Кожному нашому Замовнику ми готові забезпечити
                                        вигідні умови співпраці. Так як ми є ексклюзивним дистриб’ютором світових
                                        виробників кліматичної техніки, Ліка-Комфорт може запропонувати своїм Клієнтам
                                        найкращі ціни.</p>
                                </div>
                                <div className="about-us__tab">
                                    <img src={reason5} alt=""/>
                                    <h5 className="about-us__tab-title">Оперативність і точність</h5>
                                    <p className="about-us__tab-text">Наші Клієнти можуть бути впевнені, що їх
                                        замовлення буде виготовлене і доставлене в обговорений термін. Солідний перелік
                                        партнерів та спеціалістів дозволяють доставляти та монтувати готове обладнання в
                                        найкоротші строки.</p>
                                </div>
                            </section>
                        </div>
                        {
                            props.slider.length > 0 ? (
                                <div className="about-us__block">
                                    <h2 className="page-title">Семінари та навчання нашої команди</h2>
                                    <div className="about-us__slider-wrap">
                                        <Slider {...events} className="about-us__slider">
                                            {
                                                props.slider.map((data, i) => {
                                                    return (
                                                        <Link key={i} className="about-us__slider-item" to={`blog_page/${data.slug}`}>
                                                            <div className="about-us__slider-img">
                                                                <img src={`${_images}${data.main_image}`} alt={data.title} />
                                                            </div>
                                                            <div className="about-us__slider-date">{data.date}</div>
                                                            <h4 className="about-us__slider-title">{data.title}</h4>
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </Slider>
                                        <div className="slider-pagination">
                                            <svg className="slider-pagination__arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                      d="M9.22322 14.4474C9.34151 14.388 9.43141 14.2841 9.47321 14.1585C9.51501 14.0329 9.50531 13.8959 9.44622 13.7774L6.55922 8.00042L9.44722 2.22442C9.47724 2.16566 9.49533 2.10153 9.50045 2.03574C9.50557 1.96996 9.49761 1.90381 9.47704 1.84111C9.45648 1.77841 9.4237 1.7204 9.38061 1.67043C9.33751 1.62045 9.28495 1.57951 9.22596 1.54994C9.16697 1.52038 9.1027 1.50279 9.03688 1.49818C8.97105 1.49357 8.90497 1.50204 8.84243 1.52309C8.77989 1.54414 8.72214 1.57737 8.6725 1.62084C8.62286 1.66432 8.58232 1.7172 8.55322 1.77642L5.55322 7.77642C5.51838 7.84595 5.50023 7.92265 5.50023 8.00042C5.50023 8.07819 5.51838 8.15489 5.55322 8.22442L8.55322 14.2244C8.61263 14.3427 8.71653 14.4326 8.84212 14.4744C8.96772 14.5162 9.10477 14.5065 9.22322 14.4474Z"
                                                      fill="#182316"/>
                                            </svg>
                                            <span className="slider-pagination__current">{count}</span>
                                            <span className="slider-pagination__amount">/{props.slider.length}</span>
                                            <svg className="slider-pagination__arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                      d="M6.77678 14.4474C6.65849 14.388 6.56859 14.2841 6.52679 14.1585C6.48499 14.0329 6.49469 13.8959 6.55378 13.7774L9.44078 8.00042L6.55278 2.22442C6.52276 2.16566 6.50467 2.10153 6.49955 2.03574C6.49443 1.96996 6.50239 1.90381 6.52296 1.84111C6.54352 1.77841 6.5763 1.7204 6.61939 1.67043C6.66249 1.62045 6.71505 1.57951 6.77404 1.54994C6.83303 1.52038 6.8973 1.50279 6.96312 1.49818C7.02895 1.49357 7.09503 1.50204 7.15757 1.52309C7.22011 1.54414 7.27786 1.57737 7.3275 1.62084C7.37714 1.66432 7.41768 1.7172 7.44678 1.77642L10.4468 7.77642C10.4816 7.84595 10.4998 7.92265 10.4998 8.00042C10.4998 8.07819 10.4816 8.15489 10.4468 8.22442L7.44678 14.2244C7.38737 14.3427 7.28347 14.4326 7.15788 14.4744C7.03228 14.5162 6.89523 14.5065 6.77678 14.4474Z"
                                                      fill="#182316"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            ) : ''
                        }
                    </div>
                </main>
            </div>
            <SubscribeForm name={'Підписуйтесь на знижки та новини компанії'}/>
        </div>
    )
}

const mapStateToProps = store => {
    return {
        partners: store.partnerSliderReducer.partners,
        slider: store.aboutUsReducer.slider
    }
}

const mapDispatchToProps = {
    getMainPagePartners,
    getEvents,
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs)