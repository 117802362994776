import React, {useEffect, useState} from 'react'
import {Route, Switch} from "react-router-dom";
import {withRouter} from 'react-router'
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Main from "./components/Main/Main";
import Catalog from "./components/Catalog/Catalog";
import OptCatalog from "./components/OptCatalog/OptCatalog";
import ProductPage from "./components/ProductPage/ProductPage";
import Services from "./components/Services/Services";
import ServicePage from "./components/ServicePage/ServicePage";
import Blog from "./components/Blog/Blog";
import Cases from "./components/Cases/Cases";
import Compare from "./components/Compare/Compare";
import './scss/style.scss'
import Order from "./components/Order/Order";
import BlogPage from "./components/BlogPage/BlogPage";
import BlogPageSlider from "./components/BlogPageSLider/BlogPageSlider";
import CasesPage from "./components/CasesPage/CasesPage";
import AboutUs from "./components/AboutUs/AboutUs";
import Modals from "./components/globalComponents/Modals/Modals";
import Basket from "./components/Basket/Basket";
import OrderResult from "./components/OrderResult/OrderResult";
import Page404 from "./components/Page404/Page404";
import Sitemap from "./components/Sitemap/Sitemap";
import DeliveryPayment from "./components/DeliveryPayment/DeliveryPayment";
import Wishlist from "./components/Wishlist/Wishlist";
import Profile from "./components/Profile/Profile";
import {useDispatch, useSelector} from "react-redux";
import {
    CHANGE_USER_EMAIL,
    CHANGE_USER_LASTNAME, CHANGE_USER_LOCATION,
    CHANGE_USER_NAME,
    CHANGE_USER_PHONE,
    RELOAD_COMPARE,
    RELOAD_WISHLIST,
    USER_LOGIN
} from "./redux/actionConst/action";
import {GET_DISCOUNT_PRODUCTS, GET_LOCAL_BASKET, GET_NEW_PRODUCTS, GET_USER_DATA, GET_SETTINGS} from "./redux/actionConst/getActions";
import CatalogBrands from "./components/CatalogBrands/CatalogBrands";
import DayCatalog from "./components/DayCatalog/DayCatalog";
import DiscountCatalog from "./components/DiscountCatalog/DiscountCatalog";
import BestCatalog from "./components/BestCatalog/BestCatalog";
import SearchCatalog from "./components/SearchCatalog/SearchCatalog";
import Offer from "./components/Offer/Offer";
import QuizCatalog from "./components/QuizCatalog/QuizCatalog";
import initializeGoogleAnalytics from './utils/googleAnalytics/initializeGoogleAnalytics';
import Contacts from "./components/Contats/Contacts";
import Geocode from "react-geocode";
import {Redirect} from 'react-router';

const App = ({location}) => {

    const dispatch = useDispatch()

    const isLogged = useSelector(store => store.userReducer.info.isLogged)

    useEffect(() => {
        initializeGoogleAnalytics();
        if(localStorage.getItem('basket')) {
            dispatch({type: GET_LOCAL_BASKET, payload: JSON.parse(localStorage.getItem('basket'))})
        }
        if(!isLogged && JSON.parse(localStorage.getItem('compare'))) {
            dispatch({type: RELOAD_COMPARE, payload: JSON.parse(localStorage.getItem('compare'))})
        }
        if(localStorage.getItem('token')) {
            window.axios.post('/auth/user')
                .then(res => {
                    dispatch({type: USER_LOGIN, payload: true})
                    dispatch({type: GET_USER_DATA, payload: res.data.data})
                    dispatch({type: CHANGE_USER_NAME, payload: res.data.data.name})
                    dispatch({type: CHANGE_USER_PHONE, payload: res.data.data.phone})
                    dispatch({type: CHANGE_USER_LASTNAME, payload: res.data.data.last_name})
                    dispatch({type: CHANGE_USER_EMAIL, payload: res.data.data.email})
                    dispatch({type: RELOAD_WISHLIST, payload: res.data.data.wishes})
                    dispatch({type: RELOAD_COMPARE, payload: res.data.data.compares})
                })
        }
        window.axios.get(`/products?section=new`, {
            params: {
                pageSize: 12
            }
        })
            .then(res => {
                dispatch({type: GET_NEW_PRODUCTS, payload: res.data.data})
            })
        window.axios.get(`/products?section=special`, {
            params: {
                pageSize: 12
            }
        })
            .then(res => {
                dispatch({type: GET_DISCOUNT_PRODUCTS, payload: res.data.data})
            })

        window.axios.get(`/settings`)
            .then(res => {
                dispatch({type: GET_SETTINGS, payload: res.data.data})
            })

        navigator.geolocation.getCurrentPosition(position => {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
        })

    }, [])

    const singlePage = [
        '/order',
        '/order_result',
        '/test_api',
    ]
    const [modal, setModal] = useState(false)
    const [quiz, setQuiz] = useState(false)
    const [productSliderModal, setProductSliderModal] = useState(false)
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');

    useEffect(()=> {
        if(lat.length && lng.length) {
            Geocode.setApiKey('');
            Geocode.fromLatLng(lat, lng, 'AIzaSyC4oTEy7cOiZt1jdhXXSAh7II9Sxx6qfSM',  'uk').then(
                (response) => {
                    let city;
                    for (let i = 0; i < response.results[0].address_components.length; i++) {
                        for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                            switch (response.results[0].address_components[i].types[j]) {
                                case "locality":
                                    city = response.results[0].address_components[i].long_name;
                                    break;
                                case "administrative_area_level_1":
                                    break;
                                case "country":
                                    break;
                            }
                        }
                    }
                    dispatch({type: CHANGE_USER_LOCATION, payload: city})
                },
                (error) => {
                    console.error(error);
                }
            );
        }
    }, [lat,lng])
    const toggleModal = () => {
        setModal(!modal)
    }
    const toggleQuiz = () => {
        setQuiz(!quiz)
    }
    const toggleProductSliderModal = () => {
        setProductSliderModal(!productSliderModal)
    }

    const metaRobots = document.querySelector('meta[name="robots"]');
    if(metaRobots !== null) metaRobots.remove();

    return(
        <>
            {singlePage.indexOf(location.pathname) < 0 && <Header toggleQuiz={toggleQuiz} toggleModal={toggleModal}/>}
            <Modals
                toggleModal={toggleModal}
                modal={modal}
                quiz={quiz}
                toggleQuiz={toggleQuiz}
                toggleProductSliderModal={toggleProductSliderModal}
                productSliderModal={productSliderModal}
            />
            <Switch>
                <Route
                    exact
                    path='/'
                    component={Main}
                />
                <Route
                    path='/catalog/:slug'
                    component={Catalog}
                />
                <Route
                    path='/catalog_brands/:slug'
                    component={CatalogBrands}
                />
                <Route
                    path='/catalog_day'
                    component={DayCatalog}
                />
                <Route
                    path='/catalog_discount'
                    component={DiscountCatalog}
                />
                <Route
                    path='/quiz_catalog'
                    component={QuizCatalog}
                />
                <Route
                    path='/catalog_best'
                    component={BestCatalog}
                />
                <Route
                    path='/search'
                    component={SearchCatalog}
                />
                <Route
                    path='/search/:slug'
                    component={SearchCatalog}
                />
                <Route
                    path='/product_card/:slug'
                    component={ProductPage}
                />
                <Route
                    path='/services'
                    component={Services}
                />
                <Route
                    path='/services_page/:slug'
                    component={ServicePage}
                />
                <Route
                    path='/blog'
                    component={Blog}
                />
                <Route
                    path='/cases'
                    component={Cases}
                />
                <Route
                    path='/compare'
                    component={Compare}
                />
                <Route
                    path='/order'
                    component={Order}
                />
                <Route
                    path='/offer'
                    component={Offer}
                />
                <Route
                    path='/order_result'
                    component={OrderResult}
                />
                <Route
                    path='/blog_page/:slug'
                    component={BlogPage}
                />
                <Route
                    path='/blog_page_slider'
                    component={BlogPageSlider}
                />
                <Route
                    path='/cases_page/:slug'
                    component={CasesPage}
                />
                <Route
                    path='/about_us'
                    component={AboutUs}
                />
                <Route
                    path='/basket'
                    component={Basket}
                />
                <Route
                    path='/delivery_payment'
                    component={DeliveryPayment}
                />
                <Route
                    path='/wishlist'
                    component={Wishlist}
                />
                <Route path='/profile' component={Profile}></Route>
                <Route path='/contacts' component={Contacts}/>
                <Route path='/sitemap' component={Sitemap}/>
                <Route path='/404' component={Page404}/>
                <Route path='/:slug' component={OptCatalog}></Route>
                <Route exact>
                    <Redirect to="/404" />
                </Route>
            </Switch>
            {singlePage.indexOf(location.pathname) < 0 && <Footer/>}
        </>
    )
}

export default withRouter(App)
