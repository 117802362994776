import React from 'react'
import deleteIco from "../../../../../img/header/modals/delete-ico.svg";
import {Link} from "react-router-dom";
import {removeCompare} from "../../../../../redux/actionCreators/compareProducts";
import {connect} from 'react-redux'
import {_images} from "../../../../../API";

const CompareModalItem = (props) => {

    const removeItem = id => {
        window.axios.delete(`/compares/${id}`)
            .catch(error => error)
    }

    return (
        <>
            {
                props.products.map((data, i) => {
                    return (
                        <div className="modal-compare__item" key={i}>
                            <div className="modal-compare__item-link">
                                <div className="modal-compare__img-wrap">
                                    <img src={`${_images}${data.images[0]}`} alt={data.name}/>
                                </div>
                                <div className="card-name-box modal-compare__item-name">
                                    <Link className="modal-compare__item-name" to={`/product_card/${data.slug}`}>{data.name}</Link>
                                </div>
                                <div className="modal-compare__price-wrap">
                                    {
                                        data.discount > 0 ? (
                                            <p className="modal-compare__price-old">{data.price} грн</p>
                                        ) : ''
                                    }
                                    {
                                        data.final_price === 0 ? (
                                            <p className="modal-compare__price" style={{fontSize: '13px'}}>За домовленістю</p>
                                        ) : (
                                            data.discount > 0 ? (
                                                <p className="modal-compare__price">
                                                    {
                                                        data.final_price.toLocaleString()
                                                    } грн
                                                </p>
                                            ) : (
                                                <p className="modal-compare__price">{data.final_price.toLocaleString()} грн</p>
                                            )
                                        )
                                    }
                                </div>
                            </div>
                            <button className="modal-compare__delete-btn" onClick={() => {
                                if(props.products.length > 1) {
                                    props.removeCompare(data.id)
                                    removeItem(data.compare_id)
                                } else {
                                    props.removeCompare(data.id)
                                    removeItem(data.compare_id)
                                    localStorage.setItem('compare', JSON.stringify([]))
                                }
                            }}>
                                <img src={deleteIco} alt='удалить'/>
                            </button>
                        </div>
                    )
                })
            }
        </>
    )
}

const mapDispatchToProps = {removeCompare}

export default connect(null, mapDispatchToProps)(CompareModalItem)