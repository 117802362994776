export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_MENU_CATALOG = 'GET_MENU_CATALOG'
export const GET_SHOW_MENU_CATALOG = 'GET_SHOW_MENU_CATALOG'
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_CATALOG_FILTERS = 'GET_CATALOG_FILTERS'
export const GET_CATALOG_FILTERS_BRANDS = 'GET_CATALOG_FILTERS_BRANDS'
export const GET_CATALOG_BANNERS = 'GET_CATALOG_BANNERS'
export const GET_CITY = 'GET_CITY'
export const GET_BRANCH_NP = 'GET_BRANCH_NP'
export const GET_BRANCH_SAT = 'GET_BRANCH_SAT'
export const GET_CASES_ARTICLES = 'GET_CASES_ARTICLES'
export const GET_CASES_ARTICLE = 'GET_CASES_ARTICLE'
export const GET_BLOG_ARTICLES = 'GET_BLOG_ARTICLES'
export const GET_BLOG_ARTICLE = 'GET_BLOG_ARTICLE'
export const GET_DISCOUNT_PRODUCTS = 'GET_DISCOUNT_PRODUCTS'
export const GET_NEW_PRODUCTS = 'GET_NEW_PRODUCTS'
export const GET_PARTNERS_SLIDES = 'GET_PARTNERS_SLIDES'
export const GET_MAIN_PAGE_BANNERS = 'GET_MAIN_PAGE_BANNERS'
export const GET_USER_DATA = 'GET_USER_DATA'
export const GET_PRODUCT_PAGE_DATA = 'GET_PRODUCT_PAGE_DATA'
export const GET_PRODUCTS_FROM_SEARCH = 'GET_PRODUCTS_FROM_SEARCH'
export const GET_LOCAL_BASKET = 'GET_LOCAL_BASKET'
export const GET_ORDER_DATA = 'GET_ORDER_DATA'
export const GET_ORDER_BTN = 'GET_ORDER_BTN'
export const GET_BLOG_CATEGORIES = 'GET_BLOG_CATEGORIES'
export const GET_CASES_CATEGORIES = 'GET_CASES_CATEGORIES'
export const GET_EVENTS_ABOUT_US = 'GET_EVENTS_ABOUT_US'
export const GET_CATEGORY_SLUG = 'GET_CATEGORY_SLUG'
export const GET_OPT_CATEGORY = 'GET_OPT_CATEGORY'
export const GET_SETTINGS = 'GET_SETTINGS'