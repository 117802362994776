import React, {Fragment} from 'react'
import JsonLd from '../JsonLd/JsonLd';
import BreadCrumbsItem from "./BreadCrumbsItem";
import {_domain} from "../../../API";

const BreadCrumbs = ({items}) => {
    const jsonLd = {
        '@context': 'https://schema.org',
        '@type': 'BreadCrumbList',
        'itemListElement': items?.map((item, index) => ({
            '@type': 'ListItem',
            'position': index+1,
            'item': {
                '@id': typeof item.link !== 'undefined' ? _domain+item.link : _domain+window.location.pathname,
                'name': item.name,
            },
        })),
    };

    return (
        <div className="bread-crumbs">
            <JsonLd data={jsonLd} />
            {
                items?.map((data, i) => {
                    return (
                        <Fragment key={i}>
                            <BreadCrumbsItem data={data}/>
                        </Fragment>
                    )
                })
            }
        </div>
    );
};

export default BreadCrumbs