import React from 'react'
import {connect} from 'react-redux'
import {addServices, addBasket} from "../../../redux/actionCreators/basketProducts";
import {toggleModal, toggleBasketModal} from "../../../redux/actionCreators/modalsCreator";
import privat from "../../../img/product-page/icon_privatbank.svg";
import mono from "../../../img/product-page/icon_monobank.svg";
import {analyticEvents} from '../../../utils/googleAnalytics/events';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const ProductPageInfoBuy = props => {
    const showCreditForActions = useSelector(store => store.settingsReducer.showCreditForActions)

    return (
        <div className="product-info__buy-section-top">
            <button className="product-info__buy-btn btn-light" onClick={() => {
                props.addBasket({
                    id: props.product.id,
                    name: props.product.name,
                    slug: props.product.slug,
                    brand: props.product.brand,
                    category: props.product.category,
                    cashback: props.product.cashback,
                    final_price: props.product.final_price,
                    price: props.product.price,
                    discount: props.product.discount,
                    discount_percent: props.product.discount_percent,
                    quantity: props.product.quantity,
                    rating: props.product.rating,
                    rates_count: props.product.rates_count,
                    images: props.product.images,
                    services: props.product.services,
                    new_services: props.service,
                    new_services_id: props.service.map(item => item.id)
                });
                analyticEvents.addBasket(props.product, 'Product detail page');
                analyticEvents.addServiceBasket(props.service, 'Product detail page');
                props.setService([]);
                props.toggleModal(true)
                props.toggleBasketModal(true)
            }}>Купити</button>
            {
                ((props.product.discount > 0 && showCreditForActions) || (props.product.discount <= 0)) && (
                    <div className="product-info__banking">
                        <div className="product-info__banking-privat">
                            <img src={privat} alt="ПриватБанк" />
                            <div className="product-info__banking-description">
                                <p>Оформлення кредиту відбувається при оплаті через WAYFORPAY</p>
                                <p>«Оплата частинами» від ПриватБанку до 6 місяців.</p>
                                <p>1. Необхідна наявність кредитної картки ПриватБанку з активованою
                                    послугою «Оплата частинами».</p>
                                <p>2. Доступний кредитний ліміт, що перевищує суму покупки.</p>
                                <p>Перший платіж буде списаний з рахунку вашої картки в мобільному
                                    додатку, в момент оформлення покупки.</p>
                            </div>
                        </div>
                        <div className="product-info__banking-monobank">
                            <img src={mono} alt="Monobank" />
                            <div className="product-info__banking-description">
                                <p>Оформлення кредиту відбувається при оплаті через WAYFORPAY</p>
                                <p>«Покупка частинами» від Monobank до 6 місяців.</p>
                                <p>1. Необхідна наявність оновленої версії додатку MonoBank на
                                    Вашому смартфоні.</p>
                                <p>2. Доступний кредитний ліміт, що перевищує суму покупки.</p>
                                <p>Перший платіж буде списаний з рахунку вашої картки в мобільному
                                    додатку, в момент оформлення покупки.</p>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                typeof props.product.category_root !== 'undefined' && props.product.category_root.opt_category !== null && (
                    <Link className="product-info__opt-btn btn-light" to={`/${props.product.category_root.opt_category.slug}`}>Гурт</Link>
                )
            }
        </div>
    )
}

const mapDispatchToProps = {
    addServices,
    addBasket,
    toggleModal,
    toggleBasketModal,
}

export default connect(null, mapDispatchToProps)(ProductPageInfoBuy)
