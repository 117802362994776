import React, {useState} from 'react'
import {connect} from 'react-redux'
import {
    addWishlist,
    removeWishlist,
} from "../../../redux/actionCreators/wishlistProducts";
import {
    addCompare,
    reloadCompare,
    removeCompare,
} from "../../../redux/actionCreators/compareProducts";
import {Link} from "react-router-dom";
import {
    toggleModal,
    toggleLogModal,
    toggleLoginModal,
} from "../../../redux/actionCreators/modalsCreator";
import {analyticEvents} from '../../../utils/googleAnalytics/events';

const ProductPageInfoCompare = (props) => {

    const [wishlistBtn, setWishlistBtn] = useState(props.wishlist.find(item => item.id === props.product.id))
    const [wishlistModal, setWishlistModal] = useState(false)
    const openWishlist = () => {
        setWishlistBtn(true)
        setWishlistModal(true)
        setTimeout(() => {
            setWishlistModal(false)
        }, 2000)
    }
    const closeWishlist = () => {
        setWishlistModal(false)
    }
    const [compareBtn, setCompareBtn] = useState(props.compare.find(item => item.id === props.product.id))
    const [compareModal, setCompareModal] = useState(false)
    const openCompare = () => {
        setCompareBtn(true)
        setCompareModal(true)
        setTimeout(() => {
            setCompareModal(false)
        }, 2000)
    }
    const closeCompare = () => {
        setCompareModal(false)
    }

    const postCompare = id => {
        window.axios.post(`/compares`, {
            product: id
        })
            .then(res => {
                props.reloadCompare(res.data)
            })
    }
    const postWishlist = id => {
        window.axios.post(`/wishes`, {
            product: id
        })
    }

    return (
        <div className="product-info__fav-compare">
            {
                props.isLogged? (
                    <button className={`product-info__fav-btn ${wishlistBtn ? 'active' : ''}`} onClick={() => {
                        if (!wishlistBtn) {
                            props.removeWishlist(props.product.id)
                            setWishlistBtn(false)
                        }
                        openWishlist()
                        props.addWishlist(props.product)
                        analyticEvents.addWishlist();
                        postWishlist(props.product.id)
                    }}>
                        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="fill"
                                  d="M15.5015 28L15.8292 27.7765C27.4309 19.6385 28.3767 13.0367 27.9085 9.82435C27.7087 8.37312 27.1073 7.00563 26.1714 5.87445C25.2356 4.74327 24.0021 3.89289 22.6086 3.41818C21.4626 3.00717 20.2288 2.90007 19.0285 3.10743C17.8283 3.31478 16.7031 3.82944 15.7637 4.60071C15.6788 4.66873 15.573 4.70582 15.464 4.70582C15.355 4.70582 15.2493 4.66873 15.1644 4.60071C14.2401 3.83272 13.1302 3.31794 11.9445 3.10727C10.7588 2.89661 9.53829 2.99735 8.40373 3.39955C7.00846 3.87284 5.77301 4.7226 4.83543 5.85388C3.89786 6.98517 3.29508 8.35341 3.09446 9.80573C2.61691 13.0274 3.56265 19.6385 15.1737 27.7765L15.5015 28Z"
                                  fill="#DB4915"/>
                            <path className="line"
                                  d="M15.119 30.7058L15.5122 30.4376C29.4343 20.672 30.5692 12.7499 30.0074 8.89503C29.7676 7.15355 29.0459 5.51257 27.9229 4.15515C26.7999 2.79774 25.3197 1.77728 23.6475 1.20762C22.2724 0.714414 20.7917 0.585895 19.3514 0.834721C17.9111 1.08355 16.5609 1.70113 15.4336 2.62666C15.3317 2.70829 15.2048 2.7528 15.074 2.7528C14.9432 2.7528 14.8163 2.70829 14.7144 2.62666C13.6053 1.70507 12.2734 1.08733 10.8506 0.834537C9.42771 0.581739 7.96313 0.702636 6.60167 1.18527C4.92734 1.75322 3.4448 2.77293 2.31971 4.13047C1.19461 5.48801 0.471287 7.1299 0.230545 8.87268C-0.342518 12.7387 0.792373 20.672 14.7257 30.4376L15.119 30.7058ZM15.4673 26.4934C15.3659 26.5661 15.244 26.6052 15.119 26.6052C14.9939 26.6052 14.8721 26.5661 14.7706 26.4934C9.6243 22.672 6.25333 18.9848 4.46672 15.2416C2.68011 11.4985 3.19699 8.98442 3.9049 7.48716C4.32694 6.61691 4.94157 5.85292 5.70274 5.25245C6.43649 4.65972 7.30414 4.25325 8.23096 4.06805C8.57519 4.00089 8.92517 3.96721 9.27596 3.96749C9.84753 3.96909 10.4156 4.05571 10.9614 4.22448C11.8511 4.50409 12.6629 4.98615 13.3324 5.63235L14.7257 7.16313C14.7783 7.22074 14.8425 7.26677 14.9141 7.29826C14.9857 7.32976 15.0631 7.34603 15.1414 7.34603C15.2197 7.34603 15.2972 7.32976 15.3688 7.29826C15.4403 7.26677 15.5045 7.22074 15.5572 7.16313L16.9056 5.68822C17.5761 5.02453 18.3968 4.53027 19.299 4.24683C20.1905 3.9725 21.136 3.91971 22.0528 4.09308C22.9696 4.26645 23.8297 4.66069 24.5577 5.24128C25.317 5.84075 25.9281 6.6052 26.3443 7.47599C26.825 8.74209 27.0247 10.0966 26.9296 11.4466C26.8346 12.7967 26.4471 14.1103 25.7937 15.2975C24.0633 18.8171 20.6024 22.5938 15.4673 26.4934Z"
                                  fill="#DB4915"/>
                        </svg>
                        <div className={`product-info__popup product-info__popup--fav ${wishlistModal? '' : 'hidden'}`}>
                            <div className="product-info__popup-text">
                                {props.product.name} додано до
                                <Link className="product-info__popup-link" to="/wishlist">
                                    Списку бажань
                                </Link>
                            </div>
                            <div className="product-info__popup-close-btn" onClick={closeWishlist}>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12.75 6.3075L11.6925 5.25L9 7.9425L6.3075 5.25L5.25 6.3075L7.9425 9L5.25 11.6925L6.3075 12.75L9 10.0575L11.6925 12.75L12.75 11.6925L10.0575 9L12.75 6.3075Z"
                                        fill="#868686"/>
                                </svg>
                            </div>
                        </div>
                    </button>
                ) : (
                    <button className={`product-info__fav-btn ${wishlistBtn ? 'active' : ''}`} onClick={() => {
                        if (!wishlistBtn) {
                            props.removeWishlist()
                            setWishlistBtn(false)
                        }
                        props.toggleLoginModal(true)
                        props.toggleLogModal(true)
                        props.toggleModal(true)
                    }}>
                        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className="fill"
                                  d="M15.5015 28L15.8292 27.7765C27.4309 19.6385 28.3767 13.0367 27.9085 9.82435C27.7087 8.37312 27.1073 7.00563 26.1714 5.87445C25.2356 4.74327 24.0021 3.89289 22.6086 3.41818C21.4626 3.00717 20.2288 2.90007 19.0285 3.10743C17.8283 3.31478 16.7031 3.82944 15.7637 4.60071C15.6788 4.66873 15.573 4.70582 15.464 4.70582C15.355 4.70582 15.2493 4.66873 15.1644 4.60071C14.2401 3.83272 13.1302 3.31794 11.9445 3.10727C10.7588 2.89661 9.53829 2.99735 8.40373 3.39955C7.00846 3.87284 5.77301 4.7226 4.83543 5.85388C3.89786 6.98517 3.29508 8.35341 3.09446 9.80573C2.61691 13.0274 3.56265 19.6385 15.1737 27.7765L15.5015 28Z"
                                  fill="#DB4915"/>
                            <path className="line"
                                  d="M15.119 30.7058L15.5122 30.4376C29.4343 20.672 30.5692 12.7499 30.0074 8.89503C29.7676 7.15355 29.0459 5.51257 27.9229 4.15515C26.7999 2.79774 25.3197 1.77728 23.6475 1.20762C22.2724 0.714414 20.7917 0.585895 19.3514 0.834721C17.9111 1.08355 16.5609 1.70113 15.4336 2.62666C15.3317 2.70829 15.2048 2.7528 15.074 2.7528C14.9432 2.7528 14.8163 2.70829 14.7144 2.62666C13.6053 1.70507 12.2734 1.08733 10.8506 0.834537C9.42771 0.581739 7.96313 0.702636 6.60167 1.18527C4.92734 1.75322 3.4448 2.77293 2.31971 4.13047C1.19461 5.48801 0.471287 7.1299 0.230545 8.87268C-0.342518 12.7387 0.792373 20.672 14.7257 30.4376L15.119 30.7058ZM15.4673 26.4934C15.3659 26.5661 15.244 26.6052 15.119 26.6052C14.9939 26.6052 14.8721 26.5661 14.7706 26.4934C9.6243 22.672 6.25333 18.9848 4.46672 15.2416C2.68011 11.4985 3.19699 8.98442 3.9049 7.48716C4.32694 6.61691 4.94157 5.85292 5.70274 5.25245C6.43649 4.65972 7.30414 4.25325 8.23096 4.06805C8.57519 4.00089 8.92517 3.96721 9.27596 3.96749C9.84753 3.96909 10.4156 4.05571 10.9614 4.22448C11.8511 4.50409 12.6629 4.98615 13.3324 5.63235L14.7257 7.16313C14.7783 7.22074 14.8425 7.26677 14.9141 7.29826C14.9857 7.32976 15.0631 7.34603 15.1414 7.34603C15.2197 7.34603 15.2972 7.32976 15.3688 7.29826C15.4403 7.26677 15.5045 7.22074 15.5572 7.16313L16.9056 5.68822C17.5761 5.02453 18.3968 4.53027 19.299 4.24683C20.1905 3.9725 21.136 3.91971 22.0528 4.09308C22.9696 4.26645 23.8297 4.66069 24.5577 5.24128C25.317 5.84075 25.9281 6.6052 26.3443 7.47599C26.825 8.74209 27.0247 10.0966 26.9296 11.4466C26.8346 12.7967 26.4471 14.1103 25.7937 15.2975C24.0633 18.8171 20.6024 22.5938 15.4673 26.4934Z"
                                  fill="#DB4915"/>
                        </svg>
                    </button>
                )
            }
            <button className={`product-info__compare-btn ${compareBtn ? 'active' : ''}`}  onClick={() => {
                if (!compareBtn) {
                    props.removeCompare(props.product.id)
                    setCompareBtn(false)
                }
                openCompare()
                props.addCompare(props.product)
                postCompare(props.product.id)
            }}>
                <svg width="36" height="30" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="circle" d="M19.5752 21.6982C19.5752 23.891 20.4303 25.9545 21.9808 27.5051C23.5313 29.0555 25.5948 29.9106 27.7876 29.9106C29.9803 29.9106 32.0439 29.0555 33.5944 27.5051C35.1487 25.9545 36 23.8948 36 21.6982C36 20.3719 35.677 19.057 35.0651 17.8904L29.4673 6.3945H30.406C30.9304 6.3945 31.356 5.96887 31.356 5.44443V3.85972C31.356 3.33528 30.9304 2.90965 30.406 2.90965H23.2577C23.0828 1.33633 20.7381 0.0898438 17.8689 0.0898438C14.9997 0.0898438 12.6549 1.33633 12.4801 2.90965H5.33179C4.80735 2.90965 4.38172 3.33528 4.38172 3.85972V5.44443C4.38172 5.96887 4.80735 6.3945 5.33179 6.3945H6.53267L0.934868 17.8903C0.323023 19.057 0 20.3757 0 21.6982C0 23.891 0.855062 25.9545 2.40557 27.5051C3.95609 29.0555 6.01963 29.9106 8.21239 29.9106C10.4052 29.9106 12.4687 29.0555 14.0192 27.5051C15.5735 25.9545 16.4286 23.8948 16.4286 21.6982C16.4286 20.3719 16.1056 19.057 15.4937 17.8904L9.89211 6.3945H26.1117L20.5101 17.8904C19.8982 19.057 19.5752 20.3757 19.5752 21.6982ZM13.4491 18.905L13.4643 18.9392C13.9166 19.7981 14.1446 20.7253 14.1446 21.702H2.28016C2.28016 20.7253 2.50818 19.798 2.96041 18.9392L2.96801 18.924L8.21239 8.16163L13.4491 18.905ZM22.5356 18.9392L22.5432 18.924L27.7876 8.16163L33.0244 18.9088L33.0395 18.943C33.4918 19.8019 33.7198 20.7291 33.7198 21.7058H21.8554C21.8554 20.7253 22.0834 19.7943 22.5356 18.9392Z" fill="black"/>
                </svg>
                <div className={`product-info__popup product-info__popup--compare ${compareModal? '' : 'hidden'}`}>
                    <div className="product-info__popup-text">
                        {props.product.name} додано до
                        <Link className="product-info__popup-link" to="/compare">
                            Порівняння товарів
                        </Link>
                    </div>
                    <div className="product-info__popup-close-btn" onClick={closeCompare}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12.75 6.3075L11.6925 5.25L9 7.9425L6.3075 5.25L5.25 6.3075L7.9425 9L5.25 11.6925L6.3075 12.75L9 10.0575L11.6925 12.75L12.75 11.6925L10.0575 9L12.75 6.3075Z"
                                fill="#868686"/>
                        </svg>
                    </div>
                </div>
            </button>
        </div>
    )
}

const mapStateToProps = store => {
    return {
        isLogged: store.userReducer.info.isLogged,
        wishlist: store.wishlistReducer.wishlist,
        compare: store.compareReducer.compare,
    }
}
const mapDispatchToProps = {
    addWishlist,
    addCompare,
    reloadCompare,
    toggleModal,
    toggleLogModal,
    toggleLoginModal,
    removeWishlist,
    removeCompare,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPageInfoCompare)
